const ROUTE_LOGIN = '/login';
const ROUTE_ACTIVATE = '/activate';
// Account router
const ROUTE_FORGOT_PASSWORD = '/forgot-password';
const ROUTE_RESET_PASSWORD = '/reset/password';

// Donors router
const ROUTE_DONOR_LIST = '/donor/matrix';
const ROUTE_DONOR = '/donor/:donorCode';
const ROUTE_DONOR__ADMINISTRATIVE = ROUTE_DONOR + '/infor';
const ROUTE_DONOR__DIAGNOSIS = ROUTE_DONOR + '/diagnosis';
const ROUTE_DONOR__HISTORY = ROUTE_DONOR + '/history';
const ROUTE_DONOR__CLINICAL = ROUTE_DONOR + '/clinical';
const ROUTE_DONOR__CHAMSOC_DIEUTRI = ROUTE_DONOR + '/cham-soc-dieu-tri';
const ROUTE_DONOR__TREATMENT = ROUTE_DONOR + '/treatment';
const ROUTE_DONOR__MOLECULAR_BIOLOGY = ROUTE_DONOR + '/molecular-biology';
const ROUTE_DONOR__HUYET_HOC_TRUYEN_MAU = ROUTE_DONOR + '/huyet_hoc_truyen_mau';
const ROUTE_DONOR__HOA_SINH = ROUTE_DONOR + '/hoa_sinh';
const ROUTE_DONOR__VI_SINH = ROUTE_DONOR + '/microbiology';
const ROUTE_DONOR__PATHOLOGY = ROUTE_DONOR + '/pathology';
const ROUTE_DONOR__HINH_ANH_HOC = ROUTE_DONOR + '/hinh-anh-hoc';
const ROUTE_DONOR__CONCLUSION = ROUTE_DONOR + '/conclusion';
const ROUTE_DONOR__CONCLUSION__ORGAN =
    ROUTE_DONOR__CONCLUSION + '/organ/:organId';
const ROUTE_DONOR__SURGERY = ROUTE_DONOR + '/surgery';

// Recipient router
const ROUTE_DANH_SACH_DANG_KY = '/recipient/resgister';
const ROUTE_RECIPIENT = '/recipient/:patientCode';
const ROUTE_RECIPIENT__TIEN_CAN = ROUTE_RECIPIENT + '/tien-can';
const ROUTE_RECIPIENT__DIAGNOSIS = ROUTE_RECIPIENT + '/diagnosis';
const ROUTE_RECIPIENT__TIEN_CAN_SH = ROUTE_RECIPIENT + '/tien-can-benh-ly-khac';
const ROUTE_RECIPIENT__ADMINISTRATIVE = ROUTE_RECIPIENT + '/infor';
const ROUTE_RECIPIENT__DS_TANG = ROUTE_RECIPIENT + '/organlist';
const ROUTE_RECIPIENT__GHI_CHU = ROUTE_RECIPIENT + '/ghi-chu';
const ROUTE_RECIPIENT__HINH_ANH_HOC = ROUTE_RECIPIENT + '/hinh-anh-hoc';
const ROUTE_RECIPIENT__MOLECULAR_BIOLOGY =
    ROUTE_RECIPIENT + '/molecular-biology';
const ROUTE_RECIPIENT__HUYET_HOC_TRUYEN_MAU =
    ROUTE_RECIPIENT + '/huyet-hoc-truyen-mau';
const ROUTE_RECIPIENT__HOA_SINH = ROUTE_RECIPIENT + '/hoa-sinh';
const ROUTE_RECIPIENT__MICROBIOLOGY = ROUTE_RECIPIENT + '/microbiology';
const ROUTE_RECIPIENT__PATHOLOGY = ROUTE_RECIPIENT + '/pathology';
const ROUTE_RECIPIENT__CLINICAL = ROUTE_RECIPIENT + '/clinical';
const ROUTE_RECIPIENT__CONCLUSION = ROUTE_RECIPIENT + '/conclusion';
const ROUTE_RECIPIENT__CONCLUSION__ORGAN =
    ROUTE_RECIPIENT__CONCLUSION + '/organ/:organId';

const ROUTE_RECEIVER = '/danh-sach-cho-nhan';
const ROUTE_ACCOUNT = '/account';
const ROUTE_ACCOUNT_PROFILE = '/account/profile';
const ROUTE_ACCOUNT_CHANGE_PROFILE = '/account/profile/change';
const ROUTE_ACCOUNT_PASSWORD = '/account/password';
const ROUTE_ACCOUNT_CHANGE_PASSWORD = '/account/password/change';

const ROUTE_RECEIVER_FIT = '/receiverfit/:donorCode';

const ROUTE_DANH_SACH_CHO_GHEP = '/danh-sach-cho-ghep';
const ROUTE_CHO_GHEP_TU_HIEN_CHET = '/danh-sach-cho-ghep/nguoi-hien-chet';
const ROUTE_CHO_GHEP_TU_HIEN_SONG = '/danh-sach-cho-ghep/nguoi-hien-song';
const ROUTE_DS_NGUOI_NHAN_PHU_HOP_HIEN_CHET = '/matching-list/:donorCode';
const ROUTE_COMPARE = '/so-sanh-su-phu-hop';

const BEARER_TOKEN_VARIABLE_NAME = 'token';
const USER = 'user';
const dateTimeFormat = 'HH:mm - DD/MM/YYYY';
const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';

const commonGutter = [
    { xs: 8, sm: 16, md: 24, lg: 32 },
    { xs: 8, sm: 16, md: 24, lg: 32 },
];
const commonColGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
const halfLayout = {
    wrapperCol: 12,
    labelCol: {
        span: 12,
    },
};
const fullLayout = {
    wrapperCol: 24,
    labelCol: {
        span: 12,
    },
};
const fullRowsData = { page_size: 0 };

const emailRegex = /\S+@\S+\.\S+/;
const phoneNumberRegex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
const letterRegex = /^[a-zA-Z ]{2,30}$/;
export const DEFAULT_PAGE_SIZE = 25;

export const formRules = {
    requiredField: {
        required: true,
        message: 'Trường này bắt buộc nhập.',
    },
    emailField: {
        pattern: /\S+@\S+\.\S+/,
        message: 'Email không hợp lệ',
    },
    phoneNumberField: {
        pattern: phoneNumberRegex,
        message: 'Số điện thoại không hợp lệ',
    },
    passwordField: {
        // pattern: /.{8,}$/,
        pattern: /^(?=.{8,})(?=.*(\D)).*$/,
        message: 'Mật khẩu không hợp lệ',
    },
};

export const ROLES = {
    OWNER: 'OWNER',
    COORDINATOR: 'COORDINATOR',
    INVESTIGATOR: 'INVESTIGATOR',
};

export const kidneyOrganCode = '9d2pTGrB';
export const constant = {
    ROUTE_LOGIN,
    ROUTE_ACTIVATE,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_RESET_PASSWORD,
    ROUTE_DANH_SACH_DANG_KY,
    ROUTE_RECEIVER,
    ROUTE_RECEIVER_FIT,
    ROUTE_ACCOUNT,
    ROUTE_ACCOUNT_PROFILE,
    ROUTE_ACCOUNT_PASSWORD,
    ROUTE_RECIPIENT,
    ROUTE_RECIPIENT__CLINICAL,
    ROUTE_RECIPIENT__GHI_CHU,
    ROUTE_RECIPIENT__TIEN_CAN,
    ROUTE_RECIPIENT__ADMINISTRATIVE,
    ROUTE_RECIPIENT__DIAGNOSIS,
    ROUTE_RECIPIENT__TIEN_CAN_SH,
    ROUTE_RECIPIENT__CONCLUSION,
    ROUTE_RECIPIENT__DS_TANG,
    ROUTE_RECIPIENT__MICROBIOLOGY,
    ROUTE_RECIPIENT__PATHOLOGY,
    ROUTE_RECIPIENT__HINH_ANH_HOC,
    ROUTE_RECIPIENT__MOLECULAR_BIOLOGY,
    ROUTE_RECIPIENT__HUYET_HOC_TRUYEN_MAU,
    ROUTE_RECIPIENT__HOA_SINH,
    ROUTE_RECIPIENT__CONCLUSION__ORGAN,
    ROUTE_DONOR_LIST,
    ROUTE_DONOR,
    ROUTE_DONOR__ADMINISTRATIVE,
    ROUTE_DONOR__DIAGNOSIS,
    ROUTE_DONOR__HISTORY,
    ROUTE_DONOR__CLINICAL,
    ROUTE_DONOR__TREATMENT,
    ROUTE_DONOR__MOLECULAR_BIOLOGY,
    ROUTE_DONOR__HINH_ANH_HOC,
    ROUTE_DONOR__CONCLUSION,
    ROUTE_DONOR__CONCLUSION__ORGAN,
    ROUTE_DONOR__SURGERY,
    ROUTE_DONOR__HUYET_HOC_TRUYEN_MAU,
    ROUTE_DONOR__HOA_SINH,
    ROUTE_DONOR__VI_SINH,
    ROUTE_DONOR__PATHOLOGY,
    ROUTE_DONOR__CHAMSOC_DIEUTRI,
    ROUTE_DANH_SACH_CHO_GHEP,
    ROUTE_CHO_GHEP_TU_HIEN_SONG,
    ROUTE_CHO_GHEP_TU_HIEN_CHET,
    BEARER_TOKEN_VARIABLE_NAME,
    dateTimeFormat,
    dateFormat,
    timeFormat,
    commonGutter,
    commonColGutter,
    halfLayout,
    fullLayout,
    emailRegex,
    phoneNumberRegex,
    letterRegex,
    USER,
    fullRowsData,
    ROUTE_DS_NGUOI_NHAN_PHU_HOP_HIEN_CHET,
    ROUTE_COMPARE,
    ROUTE_ACCOUNT_CHANGE_PROFILE,
    ROUTE_ACCOUNT_CHANGE_PASSWORD,
};
