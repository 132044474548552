import { FormName } from 'form_data/constant';
import { useMemo } from 'react';
import Sidebar from 'view/DonorLayout/DeceasedDonorLayout/components/Sidebar';

const LeftMenuDonor = props => {
    const menuList = useMemo(() => {
        return [
            {
                key: 'administrative',
                title: 'Thông tin hành chính',
                tabs: ['administrative'],
            },
            {
                key: 'diagnosis',
                title: 'Chẩn đoán',
                tabs: [
                    FormName.deceased_donor__chan_doan_chung,
                    FormName.deceased_donor__chan_doan,
                ],
            },
            {
                key: 'clinical',
                title: 'Lâm sàng',
                tabs: [
                    FormName.deceased_donor__dien_tien_lam_sang,
                    FormName.deceased_donor__kham_lam_sang,
                ],
            },
            {
                key: 'treatment',
                title: 'Chăm sóc và điều trị',
                tabs: [
                    FormName.deceased_donor__cham_soc_noi_khi_quan,
                    FormName.deceased_donor__cham_soc_ecmo,
                    FormName.deceased_donor__cham_soc_khi_mau_dong_mach,
                    FormName.deceased_donor__cham_soc_khi_mau_tinh_mach,
                    FormName.deceased_donor__cham_soc_ngung_tim,
                    FormName.deceased_donor__cham_soc_thuoc_van_mach,
                    FormName.deceased_donor__cham_soc_nhiem_khuan,
                ],
            },
            {
                key: 'test-results',
                title: 'Kết quả xét nghiệm',
                subItems: [
                    {
                        key: 'molecularBiology',
                        title: 'Sinh học phân tử',
                        tabs: [FormName.deceased_donor__sinh_hoc_phan_tu],
                    },
                    {
                        key: 'huyet_hoc_truyen_mau',
                        title: 'Huyết học truyền máu',
                        tabs: [
                            FormName.deceased_donor__huyet_hoc_nhom_mau,
                            FormName.deceased_donor__huyet_hoc_mau,
                            FormName.deceased_donor__huyet_hoc_dich_tuy_nao,
                            FormName.deceased_donor__huyet_hoc_nuoc_tieu,
                            FormName.deceased_donor__huyet_hoc_dong_mau,
                        ],
                    },
                    {
                        key: 'hoa_sinh',
                        title: 'Sinh hóa',
                        tabs: [
                            FormName.deceased_donor__hoa_sinh_mau,
                            FormName.deceased_donor__sinh_hoa_nuoc_tieu,
                            FormName.deceased_donor__sinh_hoa_dich_nao_tuy,
                        ],
                    },
                    {
                        key: 'vi_sinh',
                        title: 'Vi sinh',
                        tabs: [
                            FormName.deceased_donor__huyet_thanh,
                            FormName.deceased_donor__nuoi_cay,
                            FormName.deceased_donor__nhuom_gram,
                        ],
                    },
                ],
            },
            {
                key: 'hinh-anh-hoc',
                title: 'Hình ảnh học',
                tabs: [FormName.deceased_donor__hinh_anh_hoc],
            },
            {
                key: 'conclusion',
                title: 'Kết luận',
                tabs: [FormName.deceased_donor__ket_luan],
            },
            {
                key: 'surgery',
                title: 'Thông tin về cuộc mổ',
                tabs: [FormName.deceased_donor__thong_tin_cuoc_mo],
            },
        ];
    }, []);

    return (
        <Sidebar
            titleName={props?.infoData.full_name}
            menuList={menuList}
            isHideMenu={props?.isHideMenu}
            donorInfo={props?.infoData}
        />
    );
};

export default LeftMenuDonor;
